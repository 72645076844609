<!-- 
    This is the sign in page, it uses the dashboard layout in: 
    "./layouts/Default.vue" .
 -->

 <template>
    <div class="sign-in">
        
        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

            <!-- Sign In Form Column -->
            <a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
                <h1 class="mb-15">Ingresar {{ appEnv }}</h1>
                <h5 class="font-regular text-muted">Ingresa tu Correo y contraseña</h5>

                <!-- Sign In Form -->
                <a-form
                    id="components-form-demo-normal-login"
                    :form="form"
                    class="login-form"
                    @submit="handleSubmit"
                    :hideRequiredMark="true"
                >
                    <a-form-item class="mb-10" label="Correo" :colon="false">
                        <a-input 
                        v-decorator="[ 
                        'email',
                        { rules: [{ required: true, message: 'Por favor ingresa tu correo!' }] },
                        ]" placeholder="Correo" />
                    </a-form-item>
                    <a-form-item class="mb-5" label="Contraseña" :colon="false">
                        <a-input
                        v-decorator="[ 
                        'password',
                        { rules: [{ required: true, message: 'Por favor ingresa Contraseña!' }] },
                        ]" type="password" placeholder="Password" />
                    </a-form-item>
                    <!-- <a-form-item class="mb-10">
                        <a-switch v-model="rememberMe" /> Remember Me
                    </a-form-item> -->
                    <a-form-item>
                        <a-button type="primary" block html-type="submit" class="login-form-button">
                            Ingresar
                        </a-button>
                    </a-form-item>
                </a-form>
                <!-- / Sign In Form -->

                <p class="font-semibold text-muted">
                    <a :href="adminUrl" class="font-bold text-dark" target="_blank" rel="noopener noreferrer">
                    Ingresar como Administrador
                    </a>
                </p>
            </a-col>
            <!-- / Sign In Form Column -->

            <!-- Sign In Image Column -->
            <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
                <img src="images/logo.png" alt="">
            </a-col>
            <!-- Sign In Image Column -->

        </a-row>
        
    </div>
</template>

<script>
require('dotenv').config();
export default {
    data() {
        return {
            // Binded model property for "Sign In Form" switch button for "Remember Me" .
            rememberMe: true,
            adminUrl: process.env.VUE_APP_ADMIN_URL,
            appEnv: process.env.VUE_APP_ENV,
        }
    },
    beforeCreate() {
        // Creates the form and adds to it component's "form" property.
        this.form = this.$form.createForm(this, { name: 'normal_login' });

		// clear token in localstorage
		localStorage.removeItem('token');
		// clear user
		localStorage.removeItem('user');
    },
    methods: {
        // Handles input validation after submission.
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields(async (err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    // use this.$axios to post to /auth/local with identifier and password
                    // if successful, store the token in localStorage as token
                    // then redirect to /dashboard
                    this.$gtag.event('login', { method: 'Local' })

                    await this.$axios.post('/auth/local', {
                        identifier: values.email,
                        password: values.password
                    }).then(response => {
                        this.$gtag.event('success-login', { identifier: values.email })
                        console.log(response.data);
                        localStorage.setItem('token', response.data.jwt);
						// save user object to localstorage 
						localStorage.setItem('user', JSON.stringify(response.data.user));
                        this.$router.push('/dashboard');
                    }).catch(error => {
                        alert(error);
						if(error.response.status === 401){
							alert("Usuario o contraseña incorrectos");
						}
                    });
                }
            });
        },
    },
}
</script>

<style lang="scss">
    body {
        background-color: #ffffff;
    }
</style>